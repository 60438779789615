<template>
  <div class="addcontract">
    <NavBar :list="navList" :name="$route.query.type == 'edit' ? '编辑合同' : '新增合同'"></NavBar>
    <div class="addcontractBox">
      <el-form label-position="right" :model="form" ref="form" :rules="rules" label-width="180px">
        <el-form-item label="签订方式：" prop="contract_type">
          <el-radio-group v-model="form.contract_type" :disabled="$route.query.super == 1 ? true : false"
            class="labelwidth">
            <el-radio :label="10">线上签订合同</el-radio>
            <el-radio :label="20">线下已签合同</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="合同编号：" v-if="form.contract_type == 20" prop="contract_no">
          <el-input placeholder="合同编号" v-model="form.contract_no" class="labelwidth"></el-input>
        </el-form-item>
        <el-form-item label="合同文件：" v-if="form.contract_type == 20">
          <el-upload class="upload-demo" ref="upload" action :show-file-list="false" :on-change="handlePreview"
            :auto-upload="false" accept=".pdf">
            <span v-if="form.contract_tplName" style="marginright: 10px; display: block">{{ form.contract_tplName
            }}</span>
            <el-button icon="el-icon-upload2" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="合同文件：" v-if="form.contract_tpl && type != 'renew'">
          <MyButton @click="checktpl">查看合同文件</MyButton>
        </el-form-item>
        <!-- <el-form-item label="合同文件：" v-if="form.contract_type == 10 && form.contract_address">
          <el-button type="primary" >查看合同文件</el-button>
        </el-form-item> -->
        <el-form-item label="商户:" prop="merchants_id">
          <el-select filterable v-model="form.merchants_id" @change="setChangeMerchants" placeholder="请选择商户"
            class="labelwidth">
            <el-option v-for="item in merchantsList" :key="item.merchants_id" :value="item.merchants_id"
              :label="item.contact_name"></el-option>
          </el-select>
          <myButton left @click="dialogFormVisible = true">新增商户</myButton>
        </el-form-item>
        <el-form-item label="证件号:">
          <el-input placeholder="证件号" v-model="iDNumber" :disabled="true" class="labelwidth"></el-input>
        </el-form-item>
        <el-form-item label="合同模板:" prop="property_tpl_id">
          <el-select v-model="form.property_tpl_id" @change="modelSelect" placeholder="请选择合同模板" class="labelwidth">
            <el-option v-for="item in contractTemplate" :key="item.property_setting_id" :value="item.property_setting_id"
              :label="item.values.name"></el-option>
          </el-select>
          <span class="public-tips">
            提示：合同模板在合同管理-合同设置中进行添加或修改
            <span @click="
              $router.push('/contract/contractSetting/contractsSetting')
              ">点击前往</span>
          </span>
        </el-form-item>
        <!-- <el-form-item label="意向金转余额：">
          <el-select v-model="contractSpecial" multiple filterable placeholder="请选择" class="labelwidth">
            <el-option v-for="item in earnestMoney" :key="item.purpose_id" :label="item.name"
              :value="item.purpose_id"></el-option>
          </el-select>
          <MyButton left @click="inquire">确认</MyButton>
          <span class="public-tips">
            提示：意向金在收费管理-意向金中进行添加
            <span @click="$router.push('/charge/intention')">点击前往</span>
          </span>
        </el-form-item>
        <el-form-item label="意向金金额：">
          <el-input :disabled="true" v-model="contractSpecialPrice" class="labelwidth"></el-input>
        </el-form-item> -->
        <el-form-item label="合同开始时间:" prop="contract_start">
          <el-date-picker @change="contractStart" class="labelwidth" :clearable="false" format="yyyy-MM-dd"
            value-format="yyyy-MM-dd" v-model="form.contract_start" placeholder="合同开始时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="合同到期时间:" prop="contract_end">
          <el-date-picker class="labelwidth" format="yyyy-MM-dd" :clearable="false" @change="contractEnd"
            :picker-options="pickerOptions" value-format="yyyy-MM-dd" v-model="form.contract_end"
            placeholder="合同到期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="合同内容收费名称:">
          <el-input placeholder="合同内容收费名称" v-model="form.contract_charge_name" class="labelwidth"></el-input>
        </el-form-item>
        <rentalShops></rentalShops>
        <paymentAmount></paymentAmount>
        <contractData></contractData>
        <templateField />
        <el-form-item>
          <MyButton @click="$router.push({ path: '/contract/contractlist' })" right v-if="type == 'edit'">取消</MyButton>
          <MyButton :disabled="iscomplete" @click="submit">提交</MyButton>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="合同预览" :visible.sync="previsible" :close-on-click-modal="false">
      <div class="precontent" v-html="tempContent"></div>
      <template #footer>
        <myButton @click="previsible = false">取消</myButton>
        <myButton type="primary" left @click="handleSubmit">确定</myButton>
      </template>
    </el-dialog>
    <!--    <div class="save" @click="tempSave">-->
    <!--      <img src="@/assets/img/icon/edit.png" title="暂存">-->
    <!--    </div>-->
    <el-dialog title="新增商户" :visible.sync="dialogFormVisible" width="880px" @close="diaclose"
      :close-on-click-modal="false">
      <el-form :model="{
        ...ruleform,
        merchants_card_id_list,
        contact_name_list,
        merchants_contact_list,
      }" ref="ruleform" label-width="140px">
        <el-form-item label="商户身份">
          <el-radio-group :disabled="setType == 'edit'" v-model="ruleform.merchantsType">
            <el-radio :label="1">个体</el-radio>
            <el-radio :label="2">企业</el-radio>
          </el-radio-group>
        </el-form-item>

        <div class="merchant-info" v-for="(item, index) of merchants_card_id_list" :key="index - 100">
          <div class="operation">
            <MyButton right v-if="index == merchants_card_id_list.length - 1"
              @click="hanldeAddMerchantInfo('merchants_card_id_list')">
              <template slot="preImage">
                <img src="@/unit/img/zj.png" alt="" /> </template>添加
            </MyButton>
            <MyButton type="primary" v-if="merchants_card_id_list.length != 1"
              @click="hanldeDeleteMerchantInfo(merchants_card_id_list, index)">删除</MyButton>
          </div>
          <el-form-item :label="index == 0 ? '商户身份证号' : ''" :rules="{
            required: true,
            trigger: 'blur',
            validator: checkID,
          }" v-if="ruleform.merchantsType == 1" :prop="`merchants_card_id_list.${index}.merchants_card_id`">
            <el-input v-model="item.merchants_card_id" class="public-input" placeholder="请输入商户身份证号" autocomplete="off">
            </el-input>
          </el-form-item>
          <el-form-item :label="index == 0 ? '企业税号' : ''" :rules="$setRules({ message: '请输入企业税号' })" v-else
            :prop="`merchants_card_id_list.${index}.merchants_card_id`">
            <el-input v-model="item.merchants_card_id" class="public-input" placeholder="请输入企业税号" autocomplete="off">
            </el-input>
          </el-form-item>
        </div>
        <div class="merchant-info" v-for="(item, index) of contact_name_list" :key="index + 100">
          <el-form-item :label="index == 0 ? '商户姓名' : ''" :prop="`contact_name_list.${index}.contact_name`"
            :rules="$setRules({ message: '请输入商户姓名' })">
            <el-input v-model="item.contact_name" @input="ruleform.merchants_name = ruleform.contact_name"
              class="public-input" placeholder="请输入商户姓名" autocomplete="off"></el-input>
          </el-form-item>
        </div>
        <div class="merchant-info" v-for="(item, index) of merchants_contact_list" :key="index">
          <el-form-item :label="index == 0 ? '商户联系电话' : ''" :prop="`merchants_contact_list.${index}.merchants_contact`"
            :rules="{
              required: true,
              trigger: 'blur',
              validator: checkPhone,
            }">
            <el-input v-model="item.merchants_contact" class="public-input" placeholder="请输入商户联系电话" autocomplete="off">
            </el-input>
          </el-form-item>
        </div>

        <el-form-item label="商铺名称" prop="merchantsStallName">
          <el-input v-model="ruleform.merchantsStallName" class="public-input" placeholder="请输入商铺名称"
            autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="商户logo">
          <el-upload action="" :show-file-list="false" :auto-upload="false" :on-change="(file) => {
            upload(file, 'merchantsLogo');
          }
            ">
            <img class="upimg" v-if="ruleform.merchantsLogo" :src="ruleform.merchantsLogo" alt="" />
            <el-button v-else type="primary" icon="upload">选择商户logo</el-button>
          </el-upload>
        </el-form-item>

        <el-form-item label="统一社会信用代码" prop="merchantsCreditOnly">
          <el-input v-model="ruleform.merchantsCreditOnly" class="public-input" placeholder="请输入统一社会信用代码"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="营业执照">
          <!--  -->
          <el-upload action="" :show-file-list="false" :auto-upload="false" :on-change="(file) => {
            upload(file, 'merchantsBusinessLicense');
          }
            ">
            <img class="upimg" v-if="ruleform.merchantsBusinessLicense" :src="ruleform.merchantsBusinessLicense" alt="" />
            <el-button v-else type="primary" icon="upload">选择营业执照</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="其他证件">
          <!--  -->
          <el-upload action="" :show-file-list="false" :auto-upload="false" :on-change="(file) => {
            upload(file, 'merchantsCertificate');
          }
            ">
            <img class="upimg" v-if="ruleform.merchantsCertificate" :src="ruleform.merchantsCertificate" alt="" />
            <el-button v-else type="primary" icon="upload">选择其他证件</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <MyButton @click="dialogFormVisible = false" right>取 消</MyButton>
        <MyButton type="primary" @click="merchantSubmit">确 定</MyButton>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import rentalShops from "./components/rentalShops.vue";
import paymentAmount from "./components/paymentAmount.vue";
import contractData from "./components/contractData.vue";
import templateField from "./components/templateField.vue";
import * as http from "../../../plugin/market";
import { debounceA } from "../../../unit/debounce";
import { verifyPhone, verifyId } from "@/plugin/common";

export default {
  name: "addcontract",
  components: {
    rentalShops,
    paymentAmount,
    contractData,
    templateField,
  },
  data () {
    return {
      merchants_card_id_list: [
        {
          merchants_card_id: "",
        },
      ],
      contact_name_list: [
        {
          contact_name: "",
        },
      ],
      merchants_contact_list: [
        {
          merchants_contact: "",
        },
      ],
      navList: [{ name: "新增合同" }],
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < new Date(this.form.contract_start).getTime();
        },
      },
      contractSpecial: [],
      contractSpecialPrice: "",
      rent_holiday_date: [],
      previsible: false,
      preForm: {
        contact_name: "",
        marketName: "",
      },
      dialogFormVisible: false,
      ruleform: {
        merchantsType: 1,
        merchantsCardId: "",
        merchantsStallName: "",
        merchantsName: "",
        contactName: "",
        authCateIds: [],
        merchantsLogo: "",
        merchantsContact: "",
        merchantsCreditOnly: "",
        merchantsBusinessLicense: "",
        merchantsCertificate: "",
      },
      field: [],
      id: "",
      setType: "",
    };
  },
  computed: {
    ...mapState("contract", [
      "form",
      "rules",
      "contractTemplate",
      "merchantsList",
      "reliefType",
      "contractChargeSetList",
      "iDNumber",
      "iscomplete",
      "type",
      "tempContent",
      "isLeaseStatus",
      "earnestMoney",
      "dateedShop"
    ]),
  },
  async created () {
    this.initData();
    await this.getContract();
    await this.getMerchantsList();
    await this.getContractChargeSetList();
    let id = "";
    let type = "";
    id = this.$route.query.id;
    type = this.$route.query.type;
    if (type == 'edit') {
      this.navList[0].name = '编辑合同';
    }
    if (id) {
      this.id = id;
      await this.getContractDetail({ id, type });
      let moneyT = 0;
      let str = [];
      this.form.purpose_info && this.form.purpose_info.forEach((item) => {
        str.push(item.purpose_id);
        moneyT += item.purpose_money;
      });
      this.contractSpecialPrice = moneyT;
      this.contractSpecial = str;
      if (this.$route.query.type == "renew") {
        this.contractSpecial = [];
        this.contractSpecialPrice = 0;
      }
    } else {
      let tempform = window.sessionStorage.getItem("addcontractform") || null;
      if (tempform) {
        this.setFormDetail(JSON.parse(tempform));
        // this.getContract(this.form.property_tpl_id)
        // this.tempSelect(this.form.property_tpl_id);
        // this.contractStart(this.form.contract_start);
        // this.contractEnd(this.form.contract_end)
      }
    }
    this.getTypes();
    this.getEarnestMoney();
  },
  beforeDestroy () {
    this.initData();
  },
  methods: {
    ...mapActions("contract", [
      "getContract",
      "uploadFiles",
      "getMerchantsList",
      "getContractChargeSetList",
      "getShopList",
      "getContractDetail",
      "getEarnestMoney",
    ]),
    ...mapMutations("contract", [
      "setFormData",
      "setChangeMerchants",
      "setDateedShop",
      "calculateMonthlyAmount",
      "initProgressiveIncrease",
      "calculateIncrease",
      "setIscomplete",
      "initData",
      "setIntentionshop",
      "setChangeContract",
      "setIntentionMoneyShop",
      "setMerchantsStall",
      "setFormDetail",
      "setContent",
    ]),
    checkID (rules, value, next) {
      if (verifyId(value)) {
        next();
      }
      next(new Error("请输入正确的身份证号"));
    },
    checkPhone (rules, value, next) {
      if (verifyPhone(value, { checkSpecSort: true })) {
        next();
      }
      next(new Error("请输入正确的手机号"));
    },
    hanldeAddMerchantInfo (target) {
      this.merchants_card_id_list.push({ merchants_card_id: "" });
      this.contact_name_list.push({
        contact_name: "",
      });
      this.merchants_contact_list.push({
        merchants_contact: "",
      });
    },
    hanldeDeleteMerchantInfo (target, index) {
      this.merchants_card_id_list.splice(index, 1);
      this.contact_name_list.splice(index, 1);
      this.merchants_contact_list.splice(index, 1);
    },
    checktpl () {
      let isUrl = this.form.contract_tpl.search(/https:\/\/|http:\/\//);
      if (isUrl == -1) {
        window.open(process.env.VUE_APP_LOGIN + "/" + this.form.contract_tpl);
      } else {
        window.open(this.form.contract_tpl);
      }
    },
    assemblyData () {
      if (this.form.contract_start && this.form.contract_end) {
        // let time = this.$common.monthsBetw(this.form.contract_start, this.form.contract_end);
        // let years = Math.ceil(time.months / 12);
        let years = this.$common.calculateYear(
          this.form.contract_start,
          this.form.contract_end
        );
        this.initProgressiveIncrease(years.year);
        this.calculateMonthlyAmount();
        this.calculateIncrease();
      }
      this.setIscomplete(false);
    },
    handlePreview (file) {
      if (file && file.raw) {
        let formData = new FormData();
        formData.append("file", file.raw);
        this.uploadFiles(formData);
      }
    },
    /**
     * [contractStart 选择合同开始时间，获取当前可以出租的商铺列表]
     * @param  {[type]} date               [合同开始日期]
     */
    contractStart (date) {
      if (date) {
        this.getShopList({
          endTime: date,
        });
        if (this.form.contract_end) {
          // let time = this.$common.monthsBetw(date, this.form.contract_end);
          let years = this.$common.calculateYear(date, this.form.contract_end);
          this.initProgressiveIncrease(years.year);
          // let years = Math.ceil(time.months / 12);
          // this.initProgressiveIncrease(years);
          this.calculateMonthlyAmount();
          this.calculateIncrease();
        }
      } else {
        this.setDateedShop([]);
      }
    },
    /**
     * [contractEnd 选择合同到期时间]
     * @param  {[type]} date               [description]
     * @return {[type]}      [description]
     */
    contractEnd (date) {
      if (date && this.form.contract_start) {
        // let time = this.$common.monthsBetw(this.form.contract_start, date);
        // let years = Math.ceil(time.months / 12);
        // this.initProgressiveIncrease(years);
        let years = this.$common.calculateYear(this.form.contract_start, date);
        this.initProgressiveIncrease(years.year);
        this.calculateMonthlyAmount();
        this.calculateIncrease();
      }
    },
    /**
     * [submit 新增合同提交]
     */
    submit: debounceA(
      function () {
        if (this.isLeaseStatus && !this.form.lease_status) {
          this.$common.notifyError("请确认摊位是否重复出租");
          return;
        }
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (this.form.contract_type == 10) {
              this.form.contract_no = "";
            }
            if (this.$route.query.type == "edit") {
              this.form.parent_id = this.$route.query.id;
            }
            if (this.type == "renew") {
              this.form.renew = 1;
            } else {
              this.form.renew = "";
            }
            // if(this.form.contract_type == 10){
            //     this.previsible = true;
            // } else {
            //    this.handleSubmit()
            // }
            this.handleSubmit();
          } else {
            this.$message.error("页面还有数据未填写，请补充后提交！");
          }
        });
      },
      1000,
      true
    ),
    initFormData (formData) {
      formData.contract_increasing &&
        formData.contract_increasing.forEach((item, indexz) => {
          item.fees_list && item.fees_list.forEach((ite) => {
            ite.contract_rentInfo && ite.contract_rentInfo.forEach((itm, index) => {
              itm.pay_price = itm.pay_price * 1;
              itm.property_end_time = itm.pay_end_time;
              itm.property_time = formData.contract_start;

              // 获取前一个账单周期开始时间
              if (index > 0) {
                itm.property_time = this.$common.formatTimeHms(
                  new Date(
                    ite.contract_rentInfo[index - 1].pay_end_time
                  ).getTime() +
                  24 * 60 * 60 * 1000
                );
              }
              // 处理跨年获取前一个账单周期开始时间
              if (indexz > 0 && index == 0) {
                const lastTimeFeesList = formData.contract_increasing[indexz - 1].fees_list;
                const lastContractRentInfo = lastTimeFeesList[lastTimeFeesList.length - 1].contract_rentInfo;
                const lastDate = lastContractRentInfo[lastContractRentInfo.length - 1].pay_end_time;
                itm.property_time = this.$common.formatTimeHms(new Date(lastDate).getTime() + 24 * 60 * 60 * 1000);
              }
            });
          });
        });
    },
    handleSubmit () {
      this.form.purpose_id = this.contractSpecial;
      let formData = JSON.parse(JSON.stringify(this.form));
      let url = '/contract/add';
      if (this.$route.query.super == 1) {
        url = '/contract/supperEdit';
      }
      this.initFormData(formData);
      this.$request.HttpPost(url, formData).then((res) => {
        this.$common.notifySuccess(res.msg);
        this.initData();
        this.$router.push("/contract/contractlist");
      }).finally(() => {
        this.setIscomplete(false);
      });
    },
    /**
     * [inquire 查询意向金]
     */
    inquire () {
      if (!this.form.property_tpl_id) {
        this.$common.notifyError("请选择合同模板");
        return;
      }
      if (this.contractSpecial.length == 0) {
        this.$common.notifyError("请选择意向金凭证号");
        return;
      }
      this.contractSpecialPrice = 0;
      this.form.purpose_id = [];
      this.$request.HttpPost("/purpose/getPurposeNo", {
        purpose_ids: this.contractSpecial,
      }).then((res) => {
        if (res.data) {
          let tempArr = [];
          let dataArr = [];
          res.data.forEach((item) => {
            this.form.purpose_id.push(item.purpose_id);
            this.contractSpecialPrice += item.purpose_money * 1;
            item.merchants_purpose_stall.forEach((list) => {
              list.disabled = false;
              tempArr.push({
                merchants_stall_id: list.merchants_stall_id,
              });
              list.pitchVal = "";
              dataArr.push(list);
            });
          });
          if (this.dateedShop.length == 0 && !this.form.contract_start) {
            this.setDateedShop(dataArr);
          }
          this.setMerchantsStall(tempArr);
          this.setIntentionshop(dataArr);
          this.setIntentionMoneyShop(dataArr);
        }
      });
    },
    rentHolidayBlur () {
      if (!this.form.contract_start) {
        this.$common.notifyError("请选择合同开始时间");
        return;
      }
      if (!this.form.contract_end) {
        this.$common.notifyError("请选择合同结束时间");
        return;
      }
      if (!this.form.rent_holiday) {
        return;
      }
      let time = this.$common.monthsBetw(
        this.form.contract_start,
        this.form.contract_end
      );
      if (parseInt(this.form.rent_holiday) < 0) {
        this.form.rent_holiday = 0;
        return false;
      }
      if (parseInt(this.form.rent_holiday) >= time.months) {
        this.$common.notifyError("免租期时长不能等于或者大于合同时长");
        this.form.rent_holiday = 0;
        return;
      }
      let date = this.$common.addMonth(
        this.form.contract_start,
        this.form.rent_holiday
      );
      this.form.contract_fee_start = this.form.contract_start;
      let end = this.$common.formatDateTime(date);
      this.$set(this.rent_holiday_date, 0, this.form.contract_start);
      this.$set(this.rent_holiday_date, 1, end);
      this.form.contract_fee_end = end;
      this.form.rent_holiday = parseInt(this.form.rent_holiday);
      let years = Math.ceil(time.months / 12);
      this.initProgressiveIncrease(years);
      this.calculateMonthlyAmount();
      this.calculateIncrease();
    },
    async tempSelect (e) {
      let data = this.contractTemplate.find(
        (item) => item.property_setting_id == e
      );
      this.setContent(e);
    },

    merchantSubmit () {
      this.$refs.ruleform.validate((valid) => {
        if (valid) {
          const params = {
            ...this.ruleform,
            merchantsCardId: this.merchants_card_id_list
              .map((item) => item.merchants_card_id)
              .join("/"),
            contactName: this.contact_name_list
              .map((item) => item.contact_name)
              .join("/"),
            merchantsContact: this.merchants_contact_list
              .map((item) => item.merchants_contact)
              .join("/"),
          };
          this.$request
            .HttpPost("/merchants/add", {
              ...params,
              merchantsName: params.contactName,
            })
            .then((res) => {
              this.dialogFormVisible = false;
              this.$common.notifySuccess("操作成功");
              this.getMerchantsList();
            });
        }
      });
    },
    //弃用
    getTypes () {
      return;
      // this.$request
      //   .HttpPost("/marketFoodCate/list", { pageSize: 500 })
      //   .then((res) => {
      //     if (res.data) {
      //       this.field = res.data.list;
      //     }
      //   });
    },
    upload (file, value) {
      let form = new FormData();
      form.append("file", file.raw);
      this.$request.HttpPost("/system/upload/upImg", form).then((res) => {
        this.ruleform[value] =
          process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl;
      });
    },
    diaclose () {
      for (let i in this.ruleform) {
        this.ruleform[i] = "";
      }
      this.ruleform.merchantsType = 1;
      this.merchants_card_id_list = [{}];
      this.merchants_contact_list = [{}];
      this.contact_name_list = [{}];
      this.$refs.ruleform.clearValidate();
    },
    validPhone (rules, value, callback) {
      if (!this.$valide(value)) {
        return callback(new Error("请输入正确商户联系电话"));
      }
      callback();
      console.log(value);
    },
    tempSave () {
      if (!this.id) {
        window.sessionStorage.setItem(
          "addcontractform",
          JSON.stringify(this.form)
        );
      }
    },
    modelSelect (e) {
      this.setFormData({
        name: "monthTotal",
        data: [],
      });
      this.setFormData({
        name: "contract_fee_setting",
        data: [],
      });
      this.setFormData({
        name: "dcList",
        data: [],
      });
      this.setFormData({
        name: "contract_increasing",
        data: [],
      });
      this.setFormData({
        name: "merchants_stall",
        data: [],
      });
      this.setFormData({
        name: "monthTotal",
        data: [],
      });
      this.setFormData({
        name: "month_price",
        data: 0,
      });
      this.setFormData({
        name: "total_price",
        data: 0,
      });
      this.setChangeContract(e);
      // this.tempSelect(e)
    },
  },
};
</script>

<style lang="scss" scoped>
.addcontract {
  .addcontractBox {
    margin-top: 20px;
  }

  .labelwidth {
    width: 400px !important;
  }

  .public-tips {
    margin-left: 10px;
  }
}

/deep/ .el-input-number .el-input__inner {
  text-align: left;
}

.upimg {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.save {
  position: fixed;
  top: 50%;
  right: 50px;
  cursor: pointer;
}

.merchant-info {
  position: relative;

  .operation {
    position: absolute;
    bottom: 0px;
    left: 350px;
    z-index: 99;
  }
}
</style>
